<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{ 'payments.packages.title' | translate | titlecase }}</mat-card-title>
  </mat-card-header>
  <div class="container" appVerticalScrollable>
    <app-info-box *ngIf="availablePackages && availablePackages[0] && availablePackages[0].trial && !currentPackage.trial_ends_at">
      {{ 'payments.packages.trial_info' | translate }}
    </app-info-box>
    <app-info-box *ngIf="currentPackage.isTrialling && currentPackage.trial_ends_at">
      {{ 'payments.packages.trial_ends_info' | translate: { endDate: currentPackage.trial_ends_at | date: 'dd/MM/YYYY' } }}
    </app-info-box>

    <div class="packages-container" *ngIf="availablePackages.length > 0; else loader">
      <div class="legend-container">
        <div class="header">
          <div class="headline">Choose your package</div>
          <div>{{ 'payments.packages.period' | translate }}:</div>
        </div>
        <div class="legend" *ngFor="let elementName of paymentElements; let index = index" [ngClass]="{ highlight: index % 2 === 0 }">
          {{ 'payments.packages.' + elementName | translate }}
        </div>
      </div>
      <app-payment-package
        *ngFor="let package of availablePackages"
        (packageChoosen)="changePackage(package)"
        [buyingInProgress]="buyingInProgress"
        [paymentElements]="paymentElements"
        [package]="package"
        [current]="currentPackage"
      >
      </app-payment-package>
    </div>
  </div>
</mat-card>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner mode="indeterminate" [diameter]="40"></mat-spinner>
  </div>
</ng-template>
