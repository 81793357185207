import { Injectable } from '@angular/core';
import moment from 'moment';

export enum DateRangeEnum {
  OneMonth = '1m',
  SixMonths = '6m',
  OneYear = '1y',
  YearToDate = '1yd',
  All = 'all',
}

@Injectable({
  providedIn: 'root',
})
export class DateRangeService {
  calculateSortedRangeEnum(rangeEnum: typeof DateRangeEnum): { key: string; value: DateRangeEnum }[] {
    const ranges = Object.entries(rangeEnum).map(([key, value]) => ({ key, value }));
    return ranges.sort((a, b) => {
      if (a.value === DateRangeEnum.All) return 1;
      if (b.value === DateRangeEnum.All) return -1;
      return 0;
    });
  }

  getDateRange(range: DateRangeEnum): { minDate?: number; maxDate: number } {
    const maxDate = moment().valueOf();
    let minDate: number | undefined;

    switch (range) {
      case DateRangeEnum.OneMonth:
        minDate = moment().subtract(1, 'months').valueOf();
        break;
      case DateRangeEnum.SixMonths:
        minDate = moment().subtract(6, 'months').valueOf();
        break;
      case DateRangeEnum.OneYear:
        minDate = moment().subtract(1, 'years').valueOf();
        break;
      case DateRangeEnum.YearToDate:
        minDate = moment().startOf('year').valueOf();
        break;
      case DateRangeEnum.All:
        minDate = undefined;
        break;
    }

    return { minDate, maxDate };
  }
}
