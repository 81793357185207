import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';
import { FilterLogicOperatorEnum } from '../FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class MultiSearchFilterModel extends FilterModel {
  symbol: FilterTypesEnum = FilterTypesEnum.in_array;

  constructor(
    param: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    value: any = null,
    columnType: ColumnTypesEnum,
    operator: FilterLogicOperatorEnum,
    case_sensitive?: boolean,
    additional_sources?: any,
  ) {
    super(param, value, columnType, operator, case_sensitive, additional_sources);

    if (Array.isArray(value)) {
      this.value = value;
    } else if (typeof value === 'string') {
      this.value = value
        .split(',')
        .map((id) => id.trim())
        .filter((id) => !!id);
    } else {
      this.value = [];
    }
  }

  isValid() {
    return !!this.value;
  }
}
