<app-dialog [dialogRef]="dialogRef">{{ 'components.analystics_filters_modal.title' | translate }}</app-dialog>

<mat-dialog-content>
  <div *ngIf="areConnections$ | async; else noConnectionsInfo">
    <div class="connection">
      <mat-label class="grey-label">
        {{ 'components.analystics_filters_modal.form.fields.connection.label' | translate }}
      </mat-label>
      <mat-form-field class="small-select" appearance="outline">
        <mat-select
          [panelClass]="'small-select'"
          [formControl]="projectConnectionIds"
          [multiple]="data.isMultiselect"
          placeholder="{{ 'components.analystics_filters_modal.form.fields.connection.placeholder' | translate }}"
        >
          <mat-optgroup *ngFor="let group of connectionsGroups | keyvalue" [label]="group.key | replace: { _: ' ' }">
            <mat-option
              *ngFor="let connection of group.value"
              [value]="connection.id"
              (click)="selectOneConnectionByType(group.key, connection.id)"
            >
              {{ connection | connectionLabel }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <app-form-field-error-display matError></app-form-field-error-display>
      </mat-form-field>
    </div>

    <div class="date">
      <mat-label class="grey-label">
        {{ 'components.analystics_filters_modal.form.fields.time_type.label' | translate }}
        <mat-icon
          matTooltip="{{ 'components.analystics_filters_modal.form.fields.time_type.data_range_tooltip' | translate }}"
          [matTooltipPosition]="'above'"
          matTooltipClass="grey-tooltip"
        >
          info
        </mat-icon>
      </mat-label>
      <mat-form-field class="time-type small-select" appearance="outline">
        <mat-select (selectionChange)="initValidators()" [formControl]="timeType" [panelClass]="'small-select'">
          <mat-option *ngFor="let type of timeTypes" [value]="type">
            {{ 'components.analystics_filters_modal.form.fields.time_type.choices.' + type | translate }}
          </mat-option>
        </mat-select>
        <app-form-field-error-display matError></app-form-field-error-display>
      </mat-form-field>

      <app-date-range-badge *ngIf="timeType.value === timeTypeEnum.period" (selectRange)="setRange($event)"></app-date-range-badge>

      <app-date-range-picker
        (dateChanged)="dateRangeChanged($event)"
        *ngIf="timeType.value === 'range'; else period"
        [initDateEnd]="endDate.value"
        [initDateStart]="startDate.value"
      >
      </app-date-range-picker>

      <ng-template #period>
        <div class="period-wrapper">
          <span>{{ 'components.analystics_filters_modal.form.fields.sub_days.last' | translate }}</span>
          <mat-select
            class="set-range"
            [panelClass]="'small-select'"
            [value]="subDays.value"
            placeholder="{{ 'components.analystics_filters_modal.form.fields.set_range.placeholder' | translate }}"
            (selectionChange)="subDays.setValue($event.value)"
          >
            <mat-select-trigger>
              {{ subDays.value }}
            </mat-select-trigger>
            <mat-option *ngFor="let option of daysRange" [value]="option">
              {{ option }}
              {{ 'components.analystics_filters_modal.' + (option === 1 ? 'day' : 'days') | translate }}
            </mat-option>
          </mat-select>
          <span>
            {{ 'components.analystics_filters_modal.' + (subDays.value === 1 ? 'day' : 'days') | translate }}
          </span>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #noConnectionsInfo>
    <div class="no-connections-info">
      <mat-icon>warning</mat-icon>
      <br />
      {{ 'components.analystics_filters_modal.no_connections_info' | translate }}
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngIf="areConnections$ | async as areConnections; else noConnectionsBtn">
    <app-button class="analytics-button" (click)="clearFilters()" [text]="'general.cancel' | translate" color="primary"></app-button>
    <app-button
      class="analytics-button"
      (click)="submitForm()"
      [disabled]="!areConnections || !isFormValid"
      [text]="'general.apply' | translate"
      color="primary"
    ></app-button>
  </ng-container>
</mat-dialog-actions>

<ng-template #noConnectionsBtn>
  <app-button
    (click)="goToConnectionsPage()"
    [text]="'components.analystics_filters_modal.go_to_connection_btn_txt' | translate"
    color="primary"
  ></app-button>
</ng-template>
