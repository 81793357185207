import { Injectable, inject } from '@angular/core';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { ProjectService } from 'src/app/dashboard/project/project.service';

export const storageKey = {
  chartParams: 'chartParams',
  firstLogin: 'firstLogin',
  locale: 'locale',
  pagesAnalyticsData: 'pagesAnalyticsData',
  paymentPlanParams: 'paymentPlanParams',
  productsAnalyticsData: 'productsAnalyticsData',
  startPage: 'startPage',
  token: 'token',
  welcomeDialog: 'welcomeDialog',
  manualChangesWarningSkip: 'manualChangesWarningSkip',
};

export enum StartPageStorageEnum {
  projectAudit = 'audit',
  projectConnections = 'project-connections', // @TODO: prawdopodobnie nieaktualne
}

export enum WelcomeDialogStorageEnum {
  chatGpt = 'chat-gpt',
  connections = 'connections', // @TODO: prawdopodobnie nieaktualne
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private isPluginMode = isShopifyEmbedded();
  private readonly unremovableKeys = [storageKey.locale];
  private readonly projectService = inject(ProjectService);

  public clear() {
    const unremovableItems = this.unremovableKeys.map((key) => [key, this.get(key)]);
    localStorage.clear();
    unremovableItems.forEach(([key, item]) => this.save(key, item));
  }

  public get(name: string) {
    if (this.isPluginMode) {
      return null;
    }
    return JSON.parse(localStorage.getItem(name)!);
  }

  public remove(name: string) {
    if (this.isPluginMode) {
      return;
    }
    localStorage.removeItem(name);
  }

  public save(name: string, data: any) {
    Object.keys(data).length ? localStorage.setItem(name, JSON.stringify(data)) : this.remove(name);
  }

  public getForCurrentProject(name: string) {
    const curentProjectId = this.projectService.activeProject$.getValue()!.id.toString();
    return this.getNested(name, curentProjectId);
  }

  public saveForCurrentProject(name: string, data: any) {
    const curentProjectId = this.projectService.activeProject$.getValue()!.id.toString();
    return this.saveNested(name, curentProjectId, data);
  }

  public removeForCurrentProject(name: string) {
    const curentProjectId = this.projectService.activeProject$.getValue()!.id.toString();
    return this.removeNested(name, curentProjectId);
  }

  // --- Nested:

  private getNested(name: string, nested: string) {
    if (this.isPluginMode) {
      return null;
    }

    const data = this.get(name);

    if (data && data[nested]) {
      return data[nested];
    }
    return null;
  }

  private removeNested(name: string, nested: string) {
    if (this.isPluginMode) {
      return;
    }

    let obj = {};
    if (this.exist(name)) {
      obj = this.get(name);
      delete obj[nested as keyof typeof obj];

      this.save(name, obj);
    }
  }

  private saveNested(name: string, nested: string, data: any) {
    if (this.isPluginMode) {
      return null;
    }
    let obj = {};
    if (this.exist(name)) {
      obj = this.get(name);
    }

    (obj[nested as keyof typeof obj] as any) = data;

    return this.save(name, obj);
  }

  private exist(name: string): boolean {
    if (this.isPluginMode) {
      return false;
    }
    if (localStorage.getItem(name) === null) {
      return false;
    }
    return true;
  }
}
