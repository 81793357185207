<div class="tree-list">
  <ul *ngIf="items" class="parent">
    <ng-container *ngIf="items.length; else noItems">
      <li *ngFor="let item of items">
        <ng-container [ngTemplateOutletContext]="{ item }" [ngTemplateOutlet]="itemTemp"></ng-container>
      </li>
    </ng-container>
  </ul>

  <ng-template #noItems>
    <li class="no-items">
      {{ 'general.no_data' | translate }}
    </li>
  </ng-template>

  <div *appSpinner="!items; diameter: 18"></div>
</div>

<ng-template #itemTemp let-childCounter="item.children?.length || 0" let-item="item">
  <ng-container *ngIf="isVisible(item)">
    <div
      (click)="clickItem(item)"
      *appHasPermissions="item.route?.permissions || []; superior: item.route?.superiorPermission || null"
      [appRouter]="item.route?.key || null"
      [childRouteKeys]="item.route?.childKeys || null"
      [ngClass]="{ active: item.isActive }"
      [routeActiveClass]="item.noRouteActiveClass ? null! : item.children ? 'parent-active' : 'active'"
      [urlVars]="item.urlVars || null"
      class="item"
    >
      <app-icon *ngIf="item.icon?.name" [isSvg]="item.icon?.isSvg || false" [name]="item.icon.name" class="icon"></app-icon>

      <div class="labels">
        <span class="label">
          {{ item.label | translate }}
          <strong *ngIf="item.showChildCounter && childCounter" class="counter">({{ childCounter }})</strong>
        </span>
        <span *ngIf="item.subLabel" class="sub-label">{{ item.subLabel | translate }}</span>
      </div>

      <div class="expand">
        <mat-icon *ngIf="childCounter">
          {{ item.isExpanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </div>
      @let content = itemTemplate();
      @if (content) {
        <ng-container [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="content"></ng-container>
      }
    </div>

    <ul *ngIf="item.isExpanded && childCounter" class="child">
      <li *ngFor="let childItem of item.children" class="parent">
        <ng-container [ngTemplateOutletContext]="{ item: childItem }" [ngTemplateOutlet]="itemTemp"></ng-container>
      </li>
    </ul>
  </ng-container>
</ng-template>
