import { FilterGroupModel, FilterInStorageModel, FiltersGroupParamsInterface } from '../filters/models/filter.model';
import { ItemModel } from '../item.model';
import { ColumnGroupModel } from '../table-display/columns-switch/columns/ColumnGroup.model';
import { ColumnModel, ColumnsStorageSetModel } from '../table-display/columns-switch/columns/column.model';
import { CustomButtonInterface, CustomButtonPerItemInterface } from './CustomButton.model';

export interface ActionColumnInterface {
  type: 'select'; // @TODO: Will there be more types?
}

export enum DefaultTabs {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface TabsToolConfig {
  name: string;
  fiterGroup: FilterGroupModel[];
  position: 'left' | 'right';
  visible?: boolean;
  active?: boolean;
  filterSet?: FilterInStorageModel;
  defaultTabs?: DefaultTabs[];
}

export interface ConfigMemory {
  filtersRecent?: FilterInStorageModel[];
  filterSets?: FilterInStorageModel[];
  columnsSets?: ColumnsStorageSetModel[];
  tabs?: TabsToolConfig[];
}

export interface PaginatorInterface {
  isGoToPageHidden?: boolean;
  isItemsPerPageHidden?: boolean;
}

export class TableConfigurationInterface {
  tools: {
    actionButtons?: ToolInterface; // buttons container in item line
    removing?: ToolInterface; // able to remove item emits dataToRemove
    duplicate?: ToolInterface; // able to duplicate item emits itemDuplicated
    editInPopup?: ToolInterface; // able to edit item in popup
    duplicateBy?: ToolByColumnInterface; // tool to quick duplicate item by changing one column
    detailsBtn?: ToolInterface;
    duplicateParamChange?: {
      columnName: string;
      callback: (value: any, item: ItemModel) => any;
    };

    aggregationTotal?: boolean;
    aggregationSelected?: boolean;
    grouping?: boolean; // able to group items
    rule?: boolean; // able to create rules
    multiDuplicate?: boolean; // able to multi duplicate item emits itemsMultiDuplicated
    column?: boolean; // able to change columns
    select?: boolean; // select tool active
    filter?: boolean; // able to filter
    filterAdvancedGroup?: boolean; // able to create more group than one?
    filterAdvancedLogic?: boolean; // able change logcial condition or/and?
    choosingItems?: boolean; // choosing Items tool active
    // searchBy?: string; // quick search by which param
    quickFilters?: string[];
    tabs?: TabsToolConfig[];

    expandable?: boolean;
  } = {};

  selectedToolbarSlot?: CustomButtonInterface[];
  selectedToolbarDropdown?: { translationKey: string };
  selectedGloballyToolbarSlot?: CustomButtonInterface[];
  mainToolbarSlot?: CustomButtonInterface[];
  actionsButtonSlot?: CustomButtonPerItemInterface[];

  activeFilters?: FilterGroupModel[];
  columnGroups?: ColumnGroupModel[] = [];
  columns: { [key: string]: ColumnModel } = {};
  displayedColumns: string[] = [];
  groupedBy?: string[];
  inactiveStateDependencyColumn?: string;
  initFilters?: FilterGroupModel[];
  itemsPerPage?: number;
  memory?: ConfigMemory;
  navigationColumn?: string;
  readOnly?: boolean;
  requiredFields?: string[];
  sorting?: { active: string; direction: 'asc' | 'desc' } | null;
  configuration: {
    storageName: string;
  } = { storageName: '' };

  actionColumn?: ActionColumnInterface;
  initFiltersParams?: FiltersGroupParamsInterface;
  paginator?: PaginatorInterface;
}

export interface ToolInterface {
  activeAlways?: boolean;
  dependsOnParam?: string;
  position?: 'icons' | 'menu';
  singleChange?: boolean;
}

interface ToolByColumnInterface {
  activeAlways?: boolean;
  dependsOnParam?: string;
  columnBy?: string;
}
