<ng-container>
  <h2 mat-dialog-title [innerHTML]="'confirmations.new_version' | translate: { envVersion: data.env, headerVersion: data.header }"></h2>

  <mat-dialog-actions cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <button mat-raised-button color="primary" class="submit" type="submit" (click)="refresh()" cdkFocusInitial>
      {{ 'general.refresh' | translate }}
    </button>

    <div class="alternative-method">
      {{ 'refresh_app_modal.alternative_txt' | translate }}
      <a (click)="alternativeRefresh()">{{ 'refresh_app_modal.alternative_anchor_txt' | translate }}</a> ({{ 'general.click' | translate }})
    </div>
  </mat-dialog-actions>
</ng-container>
