<mat-card appearance="outlined" [ngClass]="{ selected: isSelected }">
  <mat-card-header>
    <mat-card-title>{{ translator.title }}</mat-card-title>
    <mat-card-subtitle *ngIf="showSub" class="subtitle">{{ translator.subtitle }}</mat-card-subtitle>
    <mat-card-subtitle class="description">{{ translator.description }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="tw-block md:tw-flex tw-justify-between tw-mt-4">
      <div class="price-description">
        <p class="!tw-mb-4">
          {{ 'payments.plan.support.items.' + (isActiveService ? 'active_service' : 'free') + '.price_description' | translate }}
        </p>
        <span class="price-info"
          >{{ 'general.from' | translate }}
          <span class="price">{{ isActiveService ? currenItem.price! / 100 + basicPlan!.price! / 100 : basicPlan!.price! / 100 }}</span>
          <span class="currency">{{ 'payments.plan.currencies.' + currency | translate }}/{{ 'payments.plan.month' | translate }}</span>
        </span>
      </div>
      <div *ngIf="isActiveService && currenItem.setup!" class="price-description extra">
        <p class="!tw-mb-4">
          {{ 'payments.plan.support.items.active_service.start_fee' | translate }}
          <mat-icon [matTooltip]="'payments.plan.support.items.active_service.tooltip' | translate">info</mat-icon>
        </p>
        <span class="price-info"
          ><span class="price">+{{ currenItem.setup!.price / 100 }}</span>
          <span class="currency">{{ 'payments.plan.currencies.' + currency | translate }}</span></span
        >
      </div>
    </div>

    <div *ngIf="translator.detailed">
      <div *ngFor="let detail of translator.detailed" class="items">
        <h4 *ngIf="detail.title">{{ detail.title }}:</h4>
        <ul *ngIf="detail.list">
          <li *ngFor="let item of detail.list">
            <mat-icon>check_circle</mat-icon>
            {{ item }}
          </li>
          <li *ngFor="let item of detail.list_beta">
            <mat-icon>check_circle</mat-icon>
            {{ item }} <app-beta-label [isStatic]="true"></app-beta-label>
          </li>
          <span *ngIf="detail.limits_btn">
            <button class="limits-btn" (click)="displayLimits()" mat-stroked-button>{{ detail.limits_btn }}</button>
          </span>
          <span *ngIf="detail.css">
            <li>
              <mat-icon>check_circle</mat-icon>
              {{ detail.css }} <span class="free">{{ 'payments.plan.support.gratis' | translate }}</span
              ><a class="css-display" (click)="displayCss()">{{ detail.css_display }}</a>
            </li>
          </span>
        </ul>
      </div>
    </div>

    <span *ngIf="data && isTrial && data.isTrial" class="trial-label">{{ 'payments.plan.free_version' | translate }}</span>

    <ng-container *ngIf="isActiveService">
      <div class="tw-my-3">
        <p class="tw-text-[12px] tw-leading-[16px]">
          {{ 'payments.plan.support.items.active_service.more_info' | translate }}
        </p>
      </div>
      <div class="tw-my-3">
        <p class="tw-text-[12px] tw-font-bold tw-leading-[16px]">
          {{ 'payments.plan.support.items.active_service.more_info2' | translate }}
        </p>
      </div>
      <a *ngIf="data?.learnMoreUrl" class="learn-more" [href]="data?.learnMoreUrl" target="_blank">
        {{ 'general.learn_more' | translate }}
        <mat-icon>arrow_right_alt</mat-icon>
      </a>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="select()">
      {{ 'payments.plan.support.items.' + (isActiveService ? 'active_service' : 'free') + '.select_btn' | translate }}
    </button>
    <ng-container *ngIf="isActiveService">
      <span class="tw-mx-[15px] tw-my-0">{{ 'dashboard.grid.or' | translate }}</span>
      <a mat-raised-button href="https://sembot.com/contact/" target="_blank">
        {{ 'payments.plan.support.items.active_service.make_an_appointment' | translate }}
      </a>
    </ng-container>
  </mat-card-actions>
</mat-card>
