<div class="notifications-wrapper">
  <p class="title">{{ 'profile.settings.notifications.label' | translate }}</p>
  <p class="description">{{ 'profile.settings.notifications.description' | translate }}</p>

  <div class="project-list">
    <table mat-table [dataSource]="(projects$ | async) || []" class="mat-elevation-z8">
      <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef>{{ 'general.projects' | translate }}</th>
        <td mat-cell *matCellDef="let project">{{ project.name }}</td>
      </ng-container>

      <ng-container matColumnDef="domain">
        <th mat-header-cell *matHeaderCellDef>{{ 'general.domain' | translate }}</th>
        <td mat-cell *matCellDef="let project">{{ project.domain }}</td>
      </ng-container>

      <ng-container matColumnDef="notification">
        <th mat-header-cell *matHeaderCellDef>{{ 'profile.settings.notifications.title' | translate }}</th>
        <td mat-cell *matCellDef="let project">
          <mat-slide-toggle [checked]="!!project.is_user_project_support" (change)="onNotificationChange(project, $event)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
