<button (click)="openDialog()" class="additionals-btn" class="secondary" mat-stroked-button type="button">
  <mat-icon>bar_chart</mat-icon>
  <span class="txt">
    <ng-container *ngIf="currentData; else emptyDate">
      {{
        currentData.sub_days
          ? ('components.analytics_picker.period' | translate: { subDays: currentData.sub_days })
          : ('components.analytics_picker.range'
            | translate: { startDate: currentData.start_date | date, endDate: currentData.end_date | date })
      }}

      <span
        *ngIf="currentData.project_connection_ids"
        [matBadge]="currentData.project_connection_ids.length"
        class="services-counter"
        matBadgeColor="accent"
      ></span>
    </ng-container>
    <ng-template #emptyDate>{{ 'sem_table.analytics' | translate }}</ng-template>
  </span>
</button>
