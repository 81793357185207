import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  contentChild,
  inject,
} from '@angular/core';
import { RouteInterface } from '../../model/router-config.model';

export interface TreeItemInterface {
  children?: TreeItemInterface[];
  emitData?: any;
  icon?: { name: string; isSvg?: boolean };
  id?: string;
  isActive?: boolean;
  isExpanded?: boolean;
  label?: string;
  showChildCounter?: boolean;

  // Route:
  noRouteActiveClass?: boolean;
  route?: RouteInterface;
  routeActiveClass?: string;
  urlVars?: object;
}

@Component({
  selector: 'app-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss'],
})
export class TreeListComponent implements AfterContentChecked {
  @Input() items!: TreeItemInterface[];
  @Output() selected = new EventEmitter<any>();

  itemTemplate = contentChild('itemTemplate', { read: TemplateRef });

  private cdr = inject(ChangeDetectorRef);

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  clickItem(item: TreeItemInterface) {
    if (item.children && item.children.length) {
      item.isExpanded = !item.isExpanded;
    } else if (item.emitData) {
      this.selected.emit(item.emitData);
    }
  }

  isVisible(item: TreeItemInterface) {
    const areThereCildren: boolean = !!item.children?.length;
    return areThereCildren || item.route || item.emitData;
  }
}
