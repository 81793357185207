import config from 'src/app/configs/router.config';
import { TreeItemInterface } from 'src/app/shared/components/tree-list/tree-list.component';

const sideBarGroupItems: TreeItemInterface[] = [
  {
    children: [
      {
        icon: { name: 'ai', isSvg: true },
        route: config.aiAssistant,
        label: 'container.sidebar.items.ai_assistant',
      },
      {
        icon: { name: 'grid_view' },
        route: config.dashboard,
        label: 'container.sidebar.items.dashboard',
      },
      {
        icon: { name: 'check_circle_outline' },
        route: config.myTasksList,
        label: 'container.sidebar.items.my_tasks',
      },
    ],
  },
  {
    label: 'container.sidebar.titles.tasks',
    children: [
      {
        icon: { name: 'splitscreen' },
        label: 'container.sidebar.items.tasks_list',
        children: [
          {
            route: config.tasksList,
            label: 'container.sidebar.items.all_tasks',
          },
          {
            route: config.myTasksList,
            label: 'container.sidebar.items.my_tasks',
          },
          {
            route: config.projectTasksList,
            label: 'container.sidebar.items.project_tasks',
          },
          {
            route: config.projectTasksMessages,
            label: 'container.sidebar.items.task_messages',
          },
        ],
      },
    ],
  },
  {
    label: 'container.sidebar.titles.automation',
    children: [
      {
        label: 'container.sidebar.items.start_automation',
        route: config.automation,
        icon: { name: 'cached' },
      },
      {
        icon: { name: 'hub' },
        route: config.taskTriggersList,
        label: 'container.sidebar.items.tasks_triggers_list',
      },
    ],
  },
  {
    label: 'container.sidebar.titles.inventory',
    children: [
      {
        icon: { name: 'shopping_cart' },
        label: 'container.sidebar.items.products',
        children: [
          {
            route: config.projectProducts,
            label: 'container.sidebar.items.products_list',
          },
          {
            route: config.projectCategories,
            label: 'container.sidebar.items.categories',
          },
          {
            route: config.projectBrands,
            label: 'container.sidebar.items.brands',
          },
        ],
      },
      {
        icon: { name: 'manage_history' },
        route: config.projectRules,
        label: 'container.sidebar.items.rules',
      },
      {
        icon: { name: 'dynamic_feed' },
        route: config.projectFeeds,
        label: 'container.sidebar.items.feeds',
      },
      {
        icon: { name: 'wysiwyg' },
        route: config.projectSite,
        label: 'container.sidebar.items.site',
      },
    ],
  },

  {
    label: 'container.sidebar.titles.web_content',
    children: [
      {
        icon: { name: 'wysiwyg' },
        label: 'container.sidebar.items.pages',
        children: [
          {
            route: config.projectSiteHomepage,
            label: 'container.sidebar.items.homepage',
          },
          {
            route: config.projectSitePages,
            label: 'container.sidebar.items.all_pages',
          },
          // @TODO: Do skonczenia: DEV-135
          // {
          //   route: config.projectTextAssistant,
          //   label: 'container.sidebar.items.text_assistant',
          // },
        ],
      },
    ],
  },

  {
    route: config.channels,
    label: 'container.sidebar.titles.marketing_channels',
    children: [
      {
        icon: { name: 'monitoring' },
        label: 'container.sidebar.items.google',
        children: [
          {
            route: config.channelsGoogleCampaigns,
            label: 'container.sidebar.items.report_campaign',
          },
          {
            route: config.channelsGoogleShopping,
            label: 'container.sidebar.items.report_products',
          },
          {
            route: config.channelsGoogleAdgroups,
            label: 'container.sidebar.items.report_ad_group',
          },
          {
            route: config.channelsGoogleKeywords,
            label: 'container.sidebar.items.report_keyword',
          },
          {
            route: config.channelsGoogleSearch,
            label: 'container.sidebar.items.report_search',
          },
        ],
      },
    ],
  },
  {
    label: 'container.sidebar.titles.campaigns',
    children: [
      {
        icon: { name: 'campaign' },
        route: config.projectCampaignAdd,
        label: 'container.sidebar.items.new_campaign',
      },
      {
        icon: { name: 'lists' },
        route: config.projectCampaigns,
        label: 'container.sidebar.items.campaigns_list',
      },
      {
        icon: { name: 'photo_library' },
        route: config.projectAssets,
        label: 'container.sidebar.items.assets',
      },
    ],
  },
  {
    label: 'container.sidebar.titles.monitoring',
    children: [
      {
        icon: { name: 'sell' },
        route: config.priceComparison,
        label: 'container.sidebar.items.price_comparison',
      },
      {
        icon: { name: 'travel_explore' },
        label: 'container.sidebar.items.position_tracking',
        children: [
          {
            route: config.organicTasks,
            label: 'container.sidebar.items.organic_tasks',
          },
          {
            route: config.productAdsTasks,
            label: 'container.sidebar.items.product_ads_tasks',
          },
          {
            route: config.textAdsTasks,
            label: 'container.sidebar.items.text_ads_tasks',
          },
        ],
      },
    ],
  },
  {
    label: 'container.sidebar.titles.integrations',
    children: [
      {
        icon: { name: 'leak_add' },
        route: config.projectConnections,
        label: 'container.sidebar.items.connections',
      },
      {
        icon: { name: 'add_business' },
        route: config.comparators,
        label: 'general.comparators',
      },
    ],
  },
  {
    label: 'container.sidebar.titles.quality_control',
    children: [
      {
        icon: { name: 'content_paste_search' },
        route: config.projectAudit,
        label: 'container.sidebar.items.audit',
      },
    ],
  },
  {
    label: 'container.sidebar.titles.projects',
    children: [
      {
        icon: { name: 'list_alt' },
        route: config.projects,
        label: 'container.sidebar.items.projects',
      },
    ],
  },
  {
    id: 'starred-projects', // Dynamically completed in the component
    children: [
      {
        icon: { name: 'star' },
        label: 'container.sidebar.items.starred_projects',
      },
    ],
  },
];

export const routeKeys = {};

export const routeChildren = (items: TreeItemInterface[], parentIndex: any) => {
  items.map((item, childIndex) => {
    const childKeys = item.children
      ? item.children
          .map((child) => {
            (routeKeys[child.route!.key as keyof typeof routeKeys] as any) = `${parentIndex}.children.${childIndex}`;
            return child.route!.key;
          })
          .filter((key) => key)
      : null;

    childKeys?.length && (item.route = { ...item.route!, ...{ childKeys } });
    return item;
  });
  return items;
};

export default sideBarGroupItems.map((item, parentIndex) => {
  item.children = item.children ? routeChildren(item.children, parentIndex) : null!;

  return item;
});
