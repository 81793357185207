<app-dialog [dialogRef]="dialogRef">
  <h3>
    {{ 'projects.monitor.competitors.matched_with' | translate: { seller: productsData.seller } }}
    <app-beta-label [isStatic]="true"></app-beta-label>
  </h3>
</app-dialog>

<ng-container>
  <mat-dialog-content class="content">
    <sem-table
      #mainTable
      (changedData)="getMatchedProducts($event)"
      *ngIf="optionsForTable"
      [agreagationData]="agregationData"
      [configurate]="optionsForTable"
      [data]="tasks"
    >
    </sem-table>
  </mat-dialog-content>
</ng-container>
