<div *ngIf="audit && isLoaded; else loader">
  <app-beta-label></app-beta-label>
  <div class="audit-topbar">
    <p>
      {{ 'projects.audit.results.status' | translate }}
      <span [ngClass]="audit.status === auditStatuses.READY ? 'status-ready' : 'status-in-progress'">{{
        'projects.audit.status.' + audit.status | translate
      }}</span>
    </p>
    <div *ngIf="audit.status === auditStatuses.IN_PROGRESS" class="info">{{ 'projects.audit.results.tooltips.status' | translate }}</div>

    <div *ngIf="audit.status === auditStatuses.READY">
      <p>{{ 'projects.audit.results.date' | translate: { date: audit.created_at } }}</p>
      <p>
        {{ 'projects.audit.results.share' | translate }} <a [href]="this.auditUrl" target="_blank">{{ this.auditUrl }}</a>
        <button (click)="copyLink()" aria-label="Copy url" color="seconday" mat-icon-button>
          <mat-icon>content_copy</mat-icon>
        </button>
      </p>
    </div>
  </div>

  <div>
    <div *ngIf="audit.status === auditStatuses.READY; else auditIsNotReady">
      <div class="header">
        <div class="title">
          <mat-icon [matTooltip]="'projects.audit.results.tooltips.product_prices' | translate">help</mat-icon>

          {{ 'projects.audit.results.products_prices' | translate }}
        </div>
      </div>
      <mat-grid-list [gutterSize]="'15px'" cols="4" rowHeight="110px">
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.more_expensive' | translate }}
            </div>
            <div class="more-xpensive">
              {{ monitorAudit.product_prices!.more_expensive }}/{{ monitorAudit.product_prices!.total }}
              <mat-icon>arrow_outward</mat-icon>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.cheaper' | translate }}
            </div>
            <div class="cheaper">
              {{ monitorAudit.product_prices!.cheaper }}/{{ monitorAudit.product_prices!.total }}
              <mat-icon>arrow_outward</mat-icon>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.same' | translate }}
            </div>
            <div class="same">
              {{ monitorAudit.product_prices!.same }}/{{ monitorAudit.product_prices!.total }}
              <mat-icon>arrow_forward</mat-icon>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.not_matched' | translate }}
              <mat-icon [matTooltip]="'projects.audit.results.tooltips.not_matched' | translate">help</mat-icon>
            </div>
            <div class="same">
              {{ monitorAudit.product_prices!.not_matched }}
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="bar-wrap">
        <div class="bar" style="background-color: #6d6d6d">
          <div
            [ngStyle]="{
              width:
                (monitorAudit.product_prices!.more_expensive /
                  (monitorAudit.product_prices!.total + monitorAudit.product_prices!.not_matched)) *
                  100 +
                '%',
            }"
            class="first"
            style="background-color: #d93a31"
          >
            <span class="inner-text">{{ monitorAudit.product_prices!.more_expensive }}</span>
          </div>
          <div
            [ngStyle]="{
              width:
                (monitorAudit.product_prices!.cheaper / (monitorAudit.product_prices!.total + monitorAudit.product_prices!.not_matched)) *
                  100 +
                '%',
            }"
            class="second"
            style="background-color: #77b05f"
          >
            <span class="inner-text">{{ monitorAudit.product_prices!.cheaper }}</span>
          </div>
          <div
            [ngStyle]="{
              width:
                (monitorAudit.product_prices!.same / (monitorAudit.product_prices!!.total + monitorAudit.product_prices!.not_matched)) *
                  100 +
                '%',
            }"
            class="third"
            style="background-color: #bebebe"
          >
            <span class="inner-text">{{ monitorAudit.product_prices!.same }}</span>
          </div>
          <span class="inner-text">{{ monitorAudit.product_prices!.not_matched }}</span>
        </div>
      </div>
      <div class="legend">
        <div class="rect" style="background-color: #d93a31"></div>
        {{ 'projects.audit.results.legend.more_expensive' | translate }}
        <div class="rect" style="background-color: #77b05f; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.cheaper' | translate }}
        <div class="rect" style="background-color: #bebebe; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.same' | translate }}
        <div class="rect" style="background-color: #6d6d6d; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.not_matched' | translate }}
      </div>

      <div *ngIf="monitorAudit.product_prices!!.total !== 0">
        <div class="header">
          <div class="title">
            <mat-icon [matTooltip]="'projects.audit.results.tooltips.top_price_competitors' | translate">help</mat-icon>

            {{ 'projects.audit.results.top_price_competitors' | translate }}
          </div>
        </div>
        <div class="table">
          <app-competitors-table [data]="topCompetitorsDataSource"></app-competitors-table>
        </div>

        <div class="header">
          <div class="title">
            <mat-icon [matTooltip]="'projects.audit.results.tooltips.rest_competitors' | translate">help</mat-icon>

            {{ 'projects.audit.results.rest_competitors' | translate }}
          </div>
        </div>
        <div class="table">
          <app-competitors-table [data]="restCompetitorsDataSource"></app-competitors-table>
        </div>
      </div>

      <div class="header">
        <div class="title">
          <mat-icon [matTooltip]="'projects.audit.results.tooltips.visibility_score' | translate">help</mat-icon>

          {{ 'projects.audit.results.visibility_score' | translate }}
        </div>
      </div>
      <mat-grid-list [gutterSize]="'15px'" cols="3" rowHeight="fit">
        <mat-grid-tile>
          <div class="tile">
            <h3>
              {{ monitorAudit.total_product_phrases! - monitorAudit.visible_product_phrases_text! }}
              /{{ monitorAudit.total_product_phrases! }}
            </h3>
            {{ 'projects.audit.results.tex_ads_desc' | translate }}
          </div>

          <div *ngIf="doughnutTextAdsChartOptions" class="chart">
            <app-apex-chart [chartOptions]="doughnutTextAdsChartOptions" [type]="'donut'"></app-apex-chart>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <h3>
              {{ monitorAudit.total_product_phrases! - monitorAudit.visible_product_phrases_shopping! }}/{{
                monitorAudit.total_product_phrases!
              }}
            </h3>
            {{ 'projects.audit.results.shopping_desc' | translate }}
          </div>

          <div *ngIf="doughnutShoppingAdsChartOptions" class="chart">
            <app-apex-chart [chartOptions]="doughnutShoppingAdsChartOptions" [type]="'donut'"></app-apex-chart>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <h3>
              {{ monitorAudit.total_product_phrases! - monitorAudit.visible_product_phrases_ogranic! }}/{{
                monitorAudit.total_product_phrases!
              }}
            </h3>
            {{ 'projects.audit.results.organic_desc' | translate }}
          </div>

          <div *ngIf="doughnutOrganicChartOptions" class="chart">
            <app-apex-chart [chartOptions]="doughnutOrganicChartOptions" [type]="'donut'"></app-apex-chart>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div class="header">
        <div class="title">
          <mat-icon [matTooltip]="'projects.audit.results.tooltips.text_ads_monitoring' | translate">help</mat-icon>

          {{ 'projects.audit.results.text_ads_monitoring' | translate }}
        </div>
      </div>
      <mat-grid-list [gutterSize]="'15px'" class="ads-tiles" cols="4" rowHeight="110px">
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.text_top1' | translate }}
              <mat-icon [matTooltip]="'projects.audit.results.tooltips.text_top1' | translate">help</mat-icon>
            </div>
            <h2>{{ monitorAudit.my_ads_results!.paid!.top1! }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.text_top' | translate }}
              <mat-icon [matTooltip]="'projects.audit.results.tooltips.text_top' | translate">help</mat-icon>
            </div>
            <h2>{{ monitorAudit.my_ads_results!.paid!.top! }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.text_all' | translate }}
              <mat-icon [matTooltip]="'projects.audit.results.tooltips.text_all' | translate">help</mat-icon>
            </div>
            <h2>{{ monitorAudit.my_ads_results!.paid!.all! }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.not_displayed' | translate }}
              <mat-icon [matTooltip]="'projects.audit.results.tooltips.text_not_displayed' | translate">help</mat-icon>
            </div>
            <h2>{{ monitorAudit.my_ads_results!.paid!.not_displayed! }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div class="bar-wrap">
        <div class="bar" style="background-color: #d93a31">
          <div
            [ngStyle]="{ width: (monitorAudit.my_ads_results!.paid!.top1! / monitorAudit.total_product_phrases!) * 100 + '%' }"
            class="first"
            style="background-color: #2c6ecb"
          >
            <span class="inner-text">{{ monitorAudit.my_ads_results!.paid!.top1! }}</span>
          </div>
          <div
            [ngStyle]="{
              width:
                ((monitorAudit.my_ads_results!.paid!.top! - monitorAudit.my_ads_results!.paid!.top1!) /
                  monitorAudit.total_product_phrases!) *
                  100 +
                '%',
            }"
            class="second"
            style="background-color: #96b7e5"
          >
            <span class="inner-text">{{ monitorAudit.my_ads_results!.paid!.top! - monitorAudit.my_ads_results!.paid!.top1! }}</span>
          </div>
          <div
            [ngStyle]="{
              width:
                ((monitorAudit.my_ads_results!.paid!.all! - monitorAudit.my_ads_results!.paid!.top!) /
                  monitorAudit.total_product_phrases!) *
                  100 +
                '%',
            }"
            class="third"
            style="background-color: #d5e2f5"
          >
            <span class="inner-text">{{ monitorAudit.my_ads_results!.paid!.all! - monitorAudit.my_ads_results!.paid!.top! }}</span>
          </div>
          <span class="inner-text">{{ monitorAudit.my_ads_results!.paid!.not_displayed }}</span>
        </div>
      </div>
      <div class="legend">
        <div class="rect" style="background-color: #2c6ecb"></div>
        {{ 'projects.audit.results.legend.top1' | translate }}
        <div class="rect" style="background-color: #96b7e5; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.top_paid' | translate }}
        <div class="rect" style="background-color: #d5e2f5; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.all' | translate }}
        <div class="rect" style="background-color: #d93a31; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.not_displayed' | translate }}
      </div>

      <div class="title">
        <mat-icon [matTooltip]="'projects.audit.results.tooltips.top_competitors' | translate">help</mat-icon>

        {{ 'projects.audit.results.text_ads_competitors' | translate }}
      </div>
      <div class="table">
        <app-ads-competitors-table [data]="paidCompetitors"></app-ads-competitors-table>
      </div>

      <div class="header">
        <div class="title">
          <mat-icon [matTooltip]="'projects.audit.results.tooltips.shopping_ads_monitoring' | translate">help</mat-icon>

          {{ 'projects.audit.results.shopping_ads_monitoring' | translate }}
        </div>
      </div>
      <mat-grid-list [gutterSize]="'15px'" class="ads-tiles" cols="4" rowHeight="110px">
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.top3' | translate }}
            </div>
            <h2>{{ monitorAudit.my_ads_results!.shopping!.top3 }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.top10' | translate }}
            </div>
            <h2>{{ monitorAudit.my_ads_results!.shopping!.top10 }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.top30' | translate }}
            </div>
            <h2>{{ monitorAudit.my_ads_results!.shopping!.top30! }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.not_displayed' | translate }}
            </div>
            <h2>{{ monitorAudit.my_ads_results!.shopping!.not_displayed! }} /{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div class="bar-wrap">
        <div class="bar" style="background-color: #d93a31">
          <div
            [ngStyle]="{ width: (monitorAudit.my_ads_results!.shopping!.top3! / monitorAudit.total_product_phrases!!) * 100 + '%' }"
            class="first"
            style="background-color: #2c6ecb"
          >
            <span class="inner-text">{{ monitorAudit.my_ads_results!.shopping!.top3! }}</span>
          </div>
          <div
            [ngStyle]="{
              width:
                ((monitorAudit.my_ads_results!.shopping!.top10! - monitorAudit.my_ads_results!.shopping!.top3!) /
                  monitorAudit.total_product_phrases!!) *
                  100 +
                '%',
            }"
            class="second"
            style="background-color: #96b7e5"
          >
            <span class="inner-text">{{
              monitorAudit.my_ads_results!.shopping!.top10! - monitorAudit.my_ads_results!.shopping!.top3!
            }}</span>
          </div>
          <div
            [ngStyle]="{
              width:
                ((monitorAudit.my_ads_results!.shopping!.top30! - monitorAudit.my_ads_results!.shopping!.top10!) /
                  monitorAudit.total_product_phrases!!) *
                  100 +
                '%',
            }"
            class="third"
            style="background-color: #d5e2f5"
          >
            <span class="inner-text">{{
              monitorAudit.my_ads_results!.shopping!.top30! - monitorAudit.my_ads_results!.shopping!.top10!
            }}</span>
          </div>
          <span class="inner-text">{{ monitorAudit.my_ads_results!.shopping!.not_displayed }}</span>
        </div>
      </div>
      <div class="legend">
        <div class="rect" style="background-color: #2c6ecb"></div>
        {{ 'projects.audit.results.legend.top3' | translate }}
        <div class="rect" style="background-color: #96b7e5; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.top10' | translate }}
        <div class="rect" style="background-color: #d5e2f5; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.top30' | translate }}
        <div class="rect" style="background-color: #d93a31; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.not_displayed' | translate }}
      </div>

      <div class="header">
        <div class="title">
          <mat-icon [matTooltip]="'projects.audit.results.tooltips.top_competitors' | translate">help</mat-icon>

          {{ 'projects.audit.results.shopping_ads_competitors' | translate }}
        </div>
      </div>
      <div class="table">
        <app-ads-competitors-table [data]="shoppingCompetitors"></app-ads-competitors-table>
      </div>

      <div class="header">
        <div class="title">
          <mat-icon [matTooltip]="'projects.audit.results.tooltips.organic_monitoring' | translate">help</mat-icon>

          {{ 'projects.audit.results.organic_monitoring' | translate }}
        </div>
      </div>
      <mat-grid-list [gutterSize]="'15px'" class="ads-tiles" cols="4" rowHeight="110px">
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.top3' | translate }}
            </div>
            <h2>{{ monitorAudit.my_ads_results!.organic!.top3 }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.top10' | translate }}
            </div>
            <h2>{{ monitorAudit.my_ads_results!.organic!.top10 }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.top50' | translate }}
            </div>
            <h2>{{ monitorAudit.my_ads_results!.organic!.top50 }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="tile">
            <div>
              {{ 'projects.audit.results.not_displayed' | translate }}
            </div>
            <h2>{{ monitorAudit.my_ads_results!.organic!.not_displayed }}/{{ monitorAudit.total_product_phrases! }}</h2>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div class="bar-wrap">
        <div class="bar" style="background-color: #d93a31">
          <div
            [ngStyle]="{ width: (monitorAudit.my_ads_results!.organic!.top3! / monitorAudit.total_product_phrases!!) * 100 + '%' }"
            class="first"
            style="background-color: #2c6ecb"
          >
            <span class="inner-text">{{ monitorAudit.my_ads_results!.organic!.top3 }}</span>
          </div>
          <div
            [ngStyle]="{
              width:
                ((monitorAudit.my_ads_results!.organic!.top10! - monitorAudit.my_ads_results!.organic!.top3!) /
                  monitorAudit.total_product_phrases!!) *
                  100 +
                '%',
            }"
            class="second"
            style="background-color: #96b7e5"
          >
            <span class="inner-text">{{ monitorAudit.my_ads_results!.organic!.top10! - monitorAudit.my_ads_results!.organic!.top3! }}</span>
          </div>
          <div
            [ngStyle]="{
              width:
                ((monitorAudit.my_ads_results!.organic!.top50! - monitorAudit.my_ads_results!.organic!.top10!) /
                  monitorAudit.total_product_phrases!!) *
                  100 +
                '%',
            }"
            class="third"
            style="background-color: #d5e2f5"
          >
            <span class="inner-text">{{
              monitorAudit.my_ads_results!.organic!.top50! - monitorAudit.my_ads_results!.organic!.top10!
            }}</span>
          </div>
          <span class="inner-text">{{ monitorAudit.my_ads_results!.organic!.not_displayed! }}</span>
        </div>
      </div>
      <div class="legend">
        <div class="rect" style="background-color: #2c6ecb"></div>
        {{ 'projects.audit.results.legend.top3' | translate }}
        <div class="rect" style="background-color: #96b7e5; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.top10' | translate }}
        <div class="rect" style="background-color: #d5e2f5; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.top50' | translate }}
        <div class="rect" style="background-color: #d93a31; margin-left: 15px"></div>
        {{ 'projects.audit.results.legend.not_displayed' | translate }}
      </div>

      <div class="title">
        <mat-icon [matTooltip]="'projects.audit.results.tooltips.top_competitors' | translate">help</mat-icon>

        {{ 'projects.audit.results.organic_competitors' | translate }}
      </div>
      <div class="table">
        <app-ads-competitors-table [data]="organicCompetitors"></app-ads-competitors-table>
      </div>
    </div>

    <ng-template #auditIsNotReady>
      <app-info-box [type]="'warning'">
        <mat-icon>warning</mat-icon>
        {{ 'projects.audit.results.not_available_yet' | translate }}
      </app-info-box>
    </ng-template>
  </div>
</div>

<ng-template #loader>
  <div class="loading-container">
    <mat-spinner *ngIf="!isLoaded && audit" class="loader" diameter="50"></mat-spinner>
    <div *ngIf="!audit" class="not-exists">
      <app-info-box [type]="'warning'">
        <mat-icon>warning</mat-icon>
        {{ 'projects.audit.results.not_exists' | translate }}
      </app-info-box>
    </div>
  </div>
</ng-template>
